import * as React from "react"
import Person from "../../components/Person/Person"
import { useEffect } from "react"
import Navbar from "../../components/Navbar/Navbar"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header"
import  { useTranslation } from "../../i18n"
import i18n from "i18next"
import { graphql, StaticQuery } from "gatsby"
import { splitArrayPer } from "../../util/splitArray"
import { Helmet } from "react-helmet"

const query = graphql`
	{
		allStrapiTeamMember {
			nodes {
				nameEnglish
				nameJapanese
				title
				titleJapanese
				color
				strapi_id
				photo
			}
		}
	}
`

const Team = () => {

	useEffect(() => {
		i18n.changeLanguage("jp")
		  },
	[]);

	const { t } = useTranslation("team")


	return (
		<>
			<StaticQuery
				query={query}
				render={(data) => (
					<div className="flex flex-col">
						<Navbar />
						{/* <Header title={t("title")} description={t("desc")} /> */}
						<Helmet>
							<title>TOFU Media</title>
							<meta name="description" content={"TOFU Mediaの精鋭をご紹介します。スタッフ一同、お客様の映像制作、撮影関連プロジェクトの成功に向けて、全力で後押しさせていただきます。社員の紹介は、こちらのページをご覧ください。"} />
						</Helmet>
						{/* <div className="w-[100%] bg-tofuLightGrey h-auto  justify-center flex">
							<div className="w-[96%] mt-12 lg:mt-16 flex flex-col justify-between">
								{splitArrayPer(data.allStrapiTeamMember.nodes.sort((a: any, b: any) => { return a.strapi_id - b.strapi_id }), 3).map(
									(row, index) => (
										<div
											key={index}
											className="flex flex-col lg:flex-row items-center justify-center gap-5 mb-5 "
										>
											{row.map((teamMember: any, index2) => (
												<Person
													key={index2}
													personName={
														i18n.language === "en"
															? teamMember.nameEnglish
															: teamMember.nameJapanese
													}
													personRole={
														i18n.language === "en"
															? teamMember.title
															: teamMember.titleJapanese
													}
													personImage={teamMember.photo}
													gradientColor={teamMember.color}
												/>
											))}
										</div>
									)
								)}
							</div>
						</div> */}
						<Footer />
					</div>
				)}
			/>
		</>
	)
}

export default Team
